import axiosCall from "./axiosCall";

export const _fetch_donation_plans = () => {
    return axiosCall("/donationplan/all")
}


export const _fetch_all_events_ = () => {
    return axiosCall('/events')
}
export const _fetch_single_event_ = eventId => {
    return axiosCall(`/event/${eventId}`)
}