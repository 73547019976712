import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MapImage from "../../assets/images/main/igando_map.png";
import SideContent from "../../components/side-content";
import Footer from "../../components/footer";
import Nav from "../../components/nav";
import { _fetch_all_events_, _fetch_single_event_ } from "../../components/axios_routes";

import { Spin, notification } from "antd";
import { DateTime } from "luxon";

const EventDetailPage = () => {

    const { id: eventId } = useParams();

    const [eventDetail, setEventDetail] = useState({});
    const [loadingEvent, setLoadingEvent] = useState(true);

    const openNotificationWithIcon = (type, errMessage) => {
        notification[type]({
            message: '',
            description: errMessage
        });
    };

    const fetchSingleEvent = async () => {
        try {
            let eventData = await _fetch_single_event_(eventId);
            if (eventData.data.statusMessage === "success") {
                setEventDetail(eventData.data.message);
                setLoadingEvent(false)
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading event data. Please reload page to try again')
            }
        } catch (err) {
            openNotificationWithIcon('error', 'An error occurred while loading event data. Please reload page to try again')
        }
    }
    useEffect(() => {
        fetchSingleEvent()
    }, [])
    return (
        <Spin spinning={loadingEvent}>
            <div className="event-detail-display">
                <Nav />
                <div className="event-detail-hero">
                    {/* <div className="grid-flex">
                    <div>
                        <h2 className="event-main-title">{eventDetail.eventTitle}</h2>
                        <h5>{DateTime.fromJSDate(new Date(eventDetail?.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</h5>
                    </div>
                </div> */}
                </div>
                <div className="mini-content-main pt_5">
                    <div className="contain">
                        <div className="grid-2-bias">
                            <div className="grid_block_right main-content-body-content">
                                <h2 className="event-main-title">{eventDetail.eventTitle}</h2>
                                {/* <h5>Event Description</h5> */}
                                <p>{eventDetail.eventDescription}</p>
                                <div class="mobile-only ticket-box">
                                    <ul class="first-list">
                                        <li><span class="first">Start Time:</span><span class="second">{DateTime.fromJSDate(new Date(`Mon Dec 25 2023 ${eventDetail?.startTime}`)).toLocaleString(DateTime.TIME_SIMPLE)}</span></li>
                                        <li><span class="first">Event Date:</span><span class="second">{DateTime.fromJSDate(new Date(eventDetail?.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</span></li>
                                        <li><span class="first">Event Ends:</span><span class="second">{DateTime.fromJSDate(new Date(eventDetail?.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</span></li>
                                        {/* <li><span class="first">Tickets: </span><span class="second">{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</span></li> */}
                                        <li><span class="first">Tickets: </span><span class="second">{
                                            eventDetail?.eventPricing === "paid" ?
                                                eventDetail.eventTitle === "Alimosho Laughs Christmas Special" ?
                                                    "NGN5,000.00"
                                                    :
                                                    <>{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</>
                                                :
                                                <>{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</>
                                        }</span></li>
                                    </ul>
                                    {
                                        eventDetail.eventTitle === "Alimosho Laughs Christmas Special" ?
                                            <a href="https://selar.co/5k2t1a" class="btn_red" target="_blank">Buy Tickets Now</a>
                                            :
                                            <button class="btn_red" disabled="">Buy Tickets Now</button>
                                    }
                                </div>
                                <h5 style={{ marginBottom: 0 }}>Guest Safety Is Our First Priority</h5>
                                <p>In the thick of the ongoing sequel of COVID-19, we continue to prioritize the health and
                                    well-being of our guests, artists, staffs, and the community. We are dedicated to
                                    following all guidelines for safety while providing the best patron experience for your
                                    visit.</p>
                                <img src={MapImage} alt="igando map" />
                                <h5>88, College Street, NYSC bus stop Igando.</h5>
                                <div className="mt_3"></div>
                            </div>
                            <div className="grid_block_left">
                                <div class="ticket-box">
                                    <ul class="first-list">
                                        <li><span class="first">Start Time:</span><span class="second">{DateTime.fromJSDate(new Date(`Mon Dec 25 2023 ${eventDetail?.startTime}`)).toLocaleString(DateTime.TIME_SIMPLE)}</span></li>
                                        <li><span class="first">Event Date:</span><span class="second">{DateTime.fromJSDate(new Date(eventDetail?.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</span></li>
                                        <li><span class="first">Event Ends:</span><span class="second">{DateTime.fromJSDate(new Date(eventDetail?.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</span></li>
                                        {/* <li><span class="first">Tickets: </span><span class="second">{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</span></li> */}
                                        <li><span class="first">Tickets: </span><span class="second">{
                                            eventDetail?.eventPricing === "paid" ?
                                                eventDetail.eventTitle === "Alimosho Laughs Christmas Special" ?
                                                    "NGN5,000.00"
                                                    :
                                                    <>{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</>
                                                :
                                                <>{eventDetail?.eventPricing?.slice(0, 1)?.toUpperCase()}{eventDetail?.eventPricing?.slice(1)}</>
                                        }</span></li>
                                    </ul>
                                    {
                                        eventDetail.eventTitle === "Alimosho Laughs Christmas Special" ?
                                            <a href="https://selar.co/5k2t1a" class="btn_red" target="_blank">Buy Tickets Now</a>
                                            :
                                            <button class="btn_red" disabled="">Buy Tickets Now</button>
                                    }
                                </div>
                                <div class="ticket-social">
                                    <div class="social">
                                        <ion-icon name="logo-facebook" role="img" class="md hydrated"
                                            aria-label="logo facebook"></ion-icon>
                                        <ion-icon name="logo-instagram" role="img" class="md hydrated"
                                            aria-label="logo instagram"></ion-icon>
                                        <ion-icon name="logo-youtube" role="img" class="md hydrated"
                                            aria-label="logo youtube"></ion-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Spin>
    )
}

export default EventDetailPage;