import "../../assets/css/events.css";

import React, { useEffect, useState } from "react";
import { Skeleton, notification } from 'antd';
import { DateTime } from 'luxon';

import Nav from "../../components/nav";
import Footer from "../../components/footer";

import _1 from "../../assets/images/content/_1.webp";
import { _fetch_all_events_ } from "../../components/axios_routes";
import { Link } from "react-router-dom";

const EventsPage = () => {

    const [allEvents, setAllEvents] = useState([]);
    const [loadingEvent, setLoadingEvent] = useState(true);
    const openNotificationWithIcon = (type, errMessage) => {
        notification[type]({
            message: '',
            description: errMessage
        });
    };
    const fetchAllEvents = async () => {
        try {
            let eventData = await _fetch_all_events_();
            if (eventData.data.statusMessage === "success") {
                setAllEvents(eventData.data.message);
                setLoadingEvent(false);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading events. Please reload page to try again')
            }
        } catch (err) {
            openNotificationWithIcon('error', 'An error occurred while loading events. Please reload page to try again')
        }
    }
    let skeleton = [];
    for (let i = 0; i < 12; i++) {
        skeleton.push(
            <div>
                <Skeleton.Image active />
                <div style={{ marginTop: 10 }}></div>
                <Skeleton.Button style={{ width: '100%' }} active />
            </div>
        )
    }
    useEffect(() => {
        fetchAllEvents();
    })
    return (
        <div className="about events-page event-block-display">
            <Nav />
            <div className="event-bg">
            </div>

            <div className="homepage-links-sect recent-events">
                <div className="contain">
                    <h5 className="tile-header">Recent and Upcoming Events</h5>
                    {
                        !loadingEvent ?
                            <div className="grid-4">
                                {
                                    allEvents.reverse().map((events, index) => (
                                        <Link to={`/event/detail/${events.id}/${events.eventTitle}`} key={index}>
                                            <div key={index} className="homepage-links-grid-cover">
                                                <img src={events.displayImage} alt={events.eventTitle} />
                                                <div className="links-text-cover">
                                                    <h3 className="tile-header">{events.eventTitle}</h3>
                                                    <p className="tile-date">{DateTime.fromJSDate(new Date(events.eventStarts)).toLocaleString(DateTime.DATE_HUGE)}</p>
                                                    <p className="tile-story">{events.eventDescription}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                            :
                            <div className="grid-4">
                                {
                                    skeleton.map((skeletonCheck, index) => (
                                        <div key={index}>
                                            {skeletonCheck}
                                        </div>
                                    ))
                                }
                            </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EventsPage;